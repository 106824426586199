<script lang="ts" setup>
import type { LabelValue } from "@/types/form";

const props = defineProps<{
  modelValue: string | number | null;
  options: Array<LabelValue>;
  name?: string;
  validation?: string | object;
  disabled?: boolean;
  dataCy?: string;
  placeholder?: string;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const theModel = computed({
  get: () => {
    if (props.options === undefined) {
      return { label: "No API data", value: "" };
    }
    return props.options.reduce(
      (accumulator, option) => {
        return option.value === String(props.modelValue) ? option : accumulator;
      },
      {
        label: "",
        value: "",
      }
    );
  },
  set: (option) => {
    emit("update:modelValue", option.value as string);
  },
});
</script>

<template>
  <FieldDropdown
    v-model="theModel"
    :name="name"
    :options="options"
    :validation="validation"
    :disabled="disabled"
    :data-cy="dataCy"
    :placeholder="placeholder"
  />
</template>
